$primary: #E44726;
$secondary: #868686;

@import "~bootstrap/scss/bootstrap";

.btn-primary {
  --bs-btn-font-weight: 700;
  --bs-btn-color: var(--bs-white);
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  padding-bottom: 110px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bottom-nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  list-style: none;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: #fff;
  padding: 25px 20px;
}

.bottom-nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  color: unset;
  text-decoration: none;
}

.bottom-nav-text {
  font-size: 17px;
  margin: auto;
  text-align: center;
  font-weight: 500;
  transition: all 0.3s ease;
}

.material-icons {
  display: inline;
  font-size: 32px;
  transition: color 0.3s;
}

.bottom-navbar {
  height: 100px;
}

.content {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.p-5p {
  padding-left: 7%;
  padding-right: 7%;
}

.v-center {
  display: flex;
  align-items: center;
  height: 100vh;
}

.w-100vh {
  width: 100vw;
}
